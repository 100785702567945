/*
 *
 * Lazy load for all <picture class="inview">
 *
 * The image is loaded when the user scrolls to the image position
 * It uses jquery.inview.js
 * An animation is added to the picture until the image is loaded
 *
 */

const SELECTOR_PICTURE = 'picture.inview:not(.loaded)'
const SELECTOR_BACKGROUND_PICTURE = '.inview[data-background-src]:not(.loaded)'
const SELECTOR_PIC_INVIEW = 'picture.inview:not(.loaded), .inview[data-background-src]:not(.loaded)'
const $images = $(`${SELECTOR_PIC_INVIEW}, ${SELECTOR_BACKGROUND_PICTURE}`)
let lazyObserver
let skeletonObserver

/**
 * Adds srcset attribute to image so loading it
 * @param {*} picture
 * @param {*} visible
 */
function loadImages(picture, visible) {
  const $picture = $(picture)
  visible = visible || $picture.is(':visible')
  if (visible) {
    putSrcPicture(picture, false)
  }
}

function loadBackgroundImages(block) {
  const $block = $(block)

  if ($block.is(':visible')) {
    const datasrc = $block.data('background-src')
    if (datasrc !== '' && datasrc !== undefined) {
      $block.css('background-image', `url('${datasrc}')`)
    }
    $block.addClass('loaded')
  }
}

function loadAllImagesSelector($selector) {
  const $pictures = $selector !== undefined ? $selector.find('picture.inview:not(.loaded)') : $(SELECTOR_PIC_INVIEW)
  $pictures.each(function () {
    loadImages(this, true)
  })
}

function loadAllBackgroundImagesSelector($selector) {
  $selector.each(function () {
    loadBackgroundImages(this)
  })
}

function putSrcPicture(picture, isObserverd) {
  const $picture = $(picture)
  $picture.find('source').each(function () {
    const $source = $(this)
    $source.attr('srcset', $source.data('srcset'))
  })
  // Fix load image IE11
  const ua = window.navigator.userAgent
  const is_iexplorer = ua.indexOf('MSIE ')

  if (is_iexplorer > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    if ($picture.find('img') !== undefined && $picture.find('img') !== '' && $picture.find('img') !== null) {
      picturefill({
        reevaluate: true,
        elements: [$picture.find('img')[0]],
      })
    }
  }

  $picture.find('img').one('load', function () {
    $picture.removeClass('skeleton').addClass('loaded')
    //If is loading home-video-with-fastbooking picture (for ADA, loading alt of overflow image)
    if ($picture.parents('.home-video-with-fastbooking').length) {
      $picture.siblings('.items .without-img').removeClass('hidden')
    }
    if (isObserverd) {
      lazyObserver.unobserve(picture)
    }
  })
}

/**
 * Function check if images are intersecting and load its
 * @param {*} entries list images observed
 */
function imgLazyLoad(entries) {
  if (entries.length > 0) {
    $.each(entries, function (i, entry) {
      if (entry.isIntersecting && entry.intersectionRatio > 0) {
        const $picture = $(entry.target)
        if (!$picture.hasClass('loaded')) {
          const datasrc = $picture.data('background-src')
          if (datasrc !== '' && datasrc !== undefined) {
            $picture.removeClass('skeleton').addClass('loaded').css('background-image', `url('${datasrc}')`)
            lazyObserver.unobserve(entry.target)
          } else {
            putSrcPicture(entry.target, true)
          }
        }
      }
    })
  } else {
    lazyObserver.disconnect()
  }
}

function activeSkeleton(entries) {
  $.each(entries, function (i, entry) {
    if (entry.isIntersecting) {
      const $picture = $(entry.target)
      $picture.addClass('skeleton')
      skeletonObserver.unobserve(entry.target)
    }
  })
}

function loadImgsWithObserver($img) {
  /** -------- INTERSECTION OBSERVER LAZY LOADING -------- */
  const options = {
    root: null,
    rootMargin: '0px 0px 40px 0px',
    threshold: 0,
  }

  lazyObserver = new IntersectionObserver(imgLazyLoad, options)

  /** -------- INTERSECTION OBSERVER ACTIVE SKELETON -------- */

  const options_skeleton = {
    root: null,
    rootMargin: '0px 0px 256px 0px',
    threshold: 0,
  }

  skeletonObserver = new IntersectionObserver(activeSkeleton, options_skeleton)

  $img.each(function (i, image) {
    lazyObserver.observe(image)
    skeletonObserver.observe(image)
  })

  /** ---------- MUTATION OBSERVER ---------- */
  const body_node = $('body')[0]
  const main_node = $('[role="main"]').length ? $('[role="main"]')[0] : undefined
  const page_wrapper_node = $('.page-wrapper').length ? $('.page-wrapper')[0] : undefined
  let mutation_observer

  mutation_observer = new MutationObserver(function (mutation) {
    const newNodes = mutation[0].addedNodes
    if (newNodes !== null) {
      const $nodes = $(newNodes)
      $nodes.each(function () {
        const $node = $(this)
        $node.find(SELECTOR_PIC_INVIEW).each(function (i, image) {
          lazyObserver.observe(image)
        })
      })
    }
  })

  const mutation_options = {
    attributes: false,
    childList: true,
    characterData: false,
    subtree: false,
  }

  mutation_observer.observe(body_node, mutation_options)
  if (main_node !== undefined) {
    mutation_observer.observe(main_node, mutation_options)
  }
  if (page_wrapper_node !== undefined) {
    mutation_observer.observe(page_wrapper_node, mutation_options)
  }
}

export function addImgToObserver($block) {
  if ('IntersectionObserver' in window) {
    $block.find(SELECTOR_PIC_INVIEW).each(function (i, image) {
      lazyObserver.observe(image)
    })
  }
}

$(window).trigger('checkInView')

$().fancybox({
  selector: '[data-fancybox-lazy-img]',
  afterLoad(instance, current) {
    const $pictures = current.$content.find(SELECTOR_PICTURE).filter(':not(.loaded)')
    $pictures.each(function () {
      loadImages(this, true)
    })
  },
})

$('[data-ajax-lazy-img]').on('ajax:success', function () {
  const $pictures = $('.fancybox-container.fancybox-is-open').find(SELECTOR_PICTURE).filter(':not(.loaded)')
  $pictures.each(function () {
    loadImages(this, true)
  })
})

// Public methods
window.IB.lazyImg = {
  loadImg: loadImages,
  loadBackgroundImg: loadBackgroundImages,
  loadAllImagesSelector,
  loadAllBackgroundImgSelector: loadAllBackgroundImagesSelector,
  loadImgsWithObserver,
  addImgToObserver,
}

IB.lazyImg.loadImgsWithObserver($images)
